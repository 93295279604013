<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
        <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Complexity Levels</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Apply this rules for</label>
                    <v-radio-group 
                        v-model="approvalRules.idKindWork" 
                        row
                        :rules=[validations.required]
                    >
                        <v-radio
                            label="Remodel"
                            :value="1"
                            color="var(--color__main)"
                        ></v-radio>
                        <v-radio
                            label="Construction"
                            :value="2"
                            color="var(--color__main)"
                        ></v-radio>
                        <v-radio
                            label="Service"
                            :value="3"
                            color="var(--color__main)"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <label>Level</label>
                    <v-text-field
                        value="1"
                        disabled
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="5"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="approvalRules.description1" 
                        disabled
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Price greater than</label>
                    <v-text-field
                        v-model="approvalRules.price1" 
                        disabled
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="approvalRules.needsApproval1"
                        label="Needs Approval"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <label>Level</label>
                    <v-text-field
                        value="2"
                        disabled
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="5"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="approvalRules.description2" 
                        disabled
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Price greater than</label>
                    <v-text-field
                        v-model="approvalRules.price2" 
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="approvalRules.needsApproval2"
                        label="Needs Approval"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <label>Level</label>
                    <v-text-field
                        value="3"
                        disabled
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="5"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="approvalRules.description3" 
                        disabled
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Price greater than</label>
                    <v-text-field
                        v-model="approvalRules.price3" 
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="approvalRules.needsApproval3"
                        label="Needs Approval"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <label>Level</label>
                    <v-text-field
                        value="4"
                        disabled
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="5"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="approvalRules.description4" 
                        disabled
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Price greater than</label>
                    <v-text-field
                        v-model="approvalRules.price4" 
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="approvalRules.needsApproval4"
                        label="Needs Approval"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>

            <ActionButtons
                :request="approvalRules"
                :loading="loading"
                :showButtons="{
                    cancel: false,
                    save: true
                }"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            approvalRules: {
                id: 0,
                idKindWork: 2,
                description1: "Pool only or pool with simple spa",
                price1: "$ 100,000.00",
                needsApproval1: 0,
                description2: "Pool with spa and/or other features",
                price2: "$ 175,000.00",
                needsApproval2: 1,
                description3: "Pool with spa and/or other features",
                price3: "$ 250,000.00",
                needsApproval3: 1,
                description4: "Pool with spa and/or other features",
                price4: "$ 350,000.00",
                needsApproval4: 1,
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 1
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("complexityLevelModule/GetById", this.id);

                    if (response.success) {
                        this.approvalRules = response.result;

                        this.approvalRules.price1 = this.approvalRules.price1Formatted;
                        this.approvalRules.price2 = this.approvalRules.price2Formatted;
                        this.approvalRules.price3 = this.approvalRules.price3Formatted;
                        this.approvalRules.price4 = this.approvalRules.price4Formatted;

                    }
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.approvalRules.id = this.id;

                    if (this.approvalRules.needsApproval1 === null) {
                        this.approvalRules.needsApproval1 = 0;
                    }
                    
                    if (this.approvalRules.needsApproval2 === null) {
                        this.approvalRules.needsApproval2 = 0;
                    }
                    
                    if (this.approvalRules.needsApproval3 === null) {
                        this.approvalRules.needsApproval3 = 0;
                    }
                    
                    if (this.approvalRules.needsApproval4 === null) {
                        this.approvalRules.needsApproval4 = 0;
                    }
                    
                    this.approvalRules.price1 = formaterDecimalBRServer(this.approvalRules.price1); 
                    this.approvalRules.price2 = formaterDecimalBRServer(this.approvalRules.price2); 
                    this.approvalRules.price3 = formaterDecimalBRServer(this.approvalRules.price3); 
                    this.approvalRules.price4 = formaterDecimalBRServer(this.approvalRules.price4); 

                    const result = await this.$store.dispatch("complexityLevelModule/CreateUpdate", this.approvalRules);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.getRegister();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                        
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>